.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .nav {
    margin-bottom: 10rem;
  }
  
  .title p{
    padding-bottom: 1.5rem;
  }
  
  .bigger {
    font-size: 2.5rem;
    font-weight: 900;
    padding-top: 0rem;
  }
  .nav p {
    display: flex;
    color:white;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  
  .nav ul {
    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  
  .nav ul li {
    margin: 1rem;
  }
  
  .nav a {
    color: rgb(194, 179, 94);
    text-decoration: none;
    font-weight: 500;
  }
  
  .nav a:hover {
    text-decoration: none;
    color: rgb(255, 225, 54);
    font-weight: 900;
  }
  
  #myVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    align-self: center;
  }
  
  .nav {
    position: static;
    top: 0;
    width: 100%;
    z-index: 1000; 
    background-color: transparent;
}

  
  
  
  
  @media (max-width: 600px) {
    .nav ul{
      flex-direction: column;
      margin: auto;
    }
  
    .nav ul li {
      text-align: center;
    }
  }