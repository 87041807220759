/* Main container to hold the sidebar and content */
.main-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

/* Styling the sidebar navigation */
.side-nav {
    width: 20%;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: auto;
    background: transparent; /* Ensure background is transparent */
}

.wedding-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    width: 250px; /* Set a fixed width */
    height: 350px; /* Set a fixed height */
}

.wedding-item img,
.wedding-item video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the media covers the entire container */
    display: block;
    transition: transform 0.3s ease;
}

.wedding-item:hover img,
.wedding-item:hover video {
    transform: scale(1.1);
}


.side-nav .title {
    margin-bottom: 2rem;
}

.side-nav .bigger {
    font-size: 1.5rem;
    font-weight: bold;
}

.side-nav ul {
    list-style: none;
    padding: 0;
}

.side-nav ul li {
    margin: 1rem 0;
}

.side-nav ul li a {
    color: rgb(194, 179, 94);
    text-decoration: none;
    font-weight: 500;
}

.side-nav ul li a:hover {
    color: rgb(255, 225, 54);
    font-weight: 900;
}

/* Content container styling */
.content-container {
    margin-left: 20%;
    padding: 2rem;
    width: 80%;
    background: transparent; /* Ensure background is transparent */
    position: relative;
    z-index: 1;
    overflow-y: auto;
    height: 100vh;
}

/* Background video styling */
.background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.page-title {
    color: rgb(194, 179, 94);
    text-align: center;
    margin-bottom: 2rem;
}

.work-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    width: 100%;
}

.work-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.work-item img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
}

.work-item:hover img {
    transform: scale(1.1);
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .main-container {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .side-nav {
        width: 100%;
        height: auto;
        position: relative;
        text-align: center; /* Center the nav items */
    }
    .side-nav .title, .side-nav ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .content-container {
        margin-left: 0;
        width: 100%;
        padding: 1rem;
        height: auto;
        overflow-x: auto; /* Enable horizontal scrolling */
    }
    .work-grid {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%; /* Ensure the grid extends horizontally */
    }
    .work-item {
        flex: 0 0 auto;
        width: 250px;
    }
}
