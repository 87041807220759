.hero-container {
    position: relative;
    height: 100vh; 
    width: 100vw;
    overflow: hidden;
  }
  
  .background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;  
    z-index: -1; 
}

  