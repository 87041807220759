* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

body {
    font-family: 'Montserrat', sans-serif;
  }

.page-title {
    font-size: 36px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin-top: 20px;
}

body {
    padding-top: 70px;  
}

.main-content {
    position: relative;
    z-index: 1;  
}



.weddings-container {
    margin-top: 100px;  /* Ensures content starts below the fixed navbar */
    padding: 20px;
    max-width: 100%;
    margin: auto;
    justify-content: center;
  }

  .weddings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 40px;  /* Larger gaps for more space between items */
    padding: 40px;  /* Padding around the entire grid for better framing */
    width: 100%;
    max-width: 1920px;  /* Limit max width to avoid overly stretched layouts */
  }
  
  .wedding-item {
    background-color: #000;  /* Provides a dark background for contrast */
    overflow: hidden;
    border-radius: 8px;
  }
  
  .wedding-item img {
    width: 100%;  /* Ensures images scale to the container width */
    height: auto;  /* Maintains image aspect ratio */
    object-fit: cover;  /* Covers the item area fully, cropping as necessary */
  }
  
  
