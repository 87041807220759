.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 5rem 0;
    text-align: center;
}

.social-links {
    margin-bottom: 2rem;
}

.social-links a {
    padding: 20px;
    color: #fff;
    text-decoration: none;
}

.social-links img {
    margin: 0 10px;
    width: 100px;
    height: 100px;
}

.contact-form {
    width: 100%;
    margin: 0 auto;  
    padding: 20px;  
}

.contact-form input,
.contact-form textarea {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;  
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white; 
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
    color: white;
}

.contact-form button {
    padding: 10px 20px;
    background-color: rgb(194, 179, 94); 
    color: #ffff;
    border: none;
    cursor: pointer;
    font-size: 1rem;
}

.footer > p {
    margin-top: 5rem;
}

.contact-us {
    color: rgb(194, 179, 94);
    padding: 2rem;
    font-size: 3rem;
    font-weight: bolder;
}

.contact-form button {
    width: 100%;  
    padding: 10px;  
    margin-bottom: 10px;    
}

.contact-form textarea {
    height: 200px;
}