.carousel-img {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;  /* Ensure the container takes the full width */
}

.carousel-img img {
    width: 100%;  /* Ensure the media takes the full width of the container */
    height: 100px;  /* Ensure the media takes the full height of the container */
    object-fit: cover;  /* This will crop the image to fit the container without stretching */
    display: block;
    transition: transform 0.3s ease;
}

.carousel-img:hover img,
.carousel-img:hover video {
    transform: scale(1.1);
}



h1 {
    font-size: 4rem;
    color: #fff;
    text-align: center;
    letter-spacing: -4px;
    margin-top: 15rem;
}
.image-slider-container {
    margin: 0 auto;
    width: 100%;
    height: auto;  
    padding: 1rem;
    position: relative;
}

.carousel-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
}

.some-of-our-work-heading {
    text-align: center;
    margin-top: -28rem;
    margin-bottom: 5rem;
    font-size: 3rem; 
    color: rgb(194, 179, 94);
    letter-spacing: -4px;
}

.zigzag-layout {
    flex-direction: column;
    align-items: center;
    margin: 0rem 0rem;
    background-color: #0d0d0d;
}

.zigzag-item {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}

.zigzag-item video {
    width: 100%;
}

.zigzag-item > div {
    width: 100%;
    height: auto;
}

.zigzag-item img {
    width: 46%;
    margin: auto;
    height: auto;    
}

.zigzag-item h3 {
    width: 100%;
    color: #fff;
    padding: 8rem 3rem 0rem 3rem;
    text-align: center;
}

.slick-slide {
    transition: transform 0.3s ease, opacity 0.3s ease; 
    transform: scale(1); 
    opacity: 0.2; 
}


.slick-center {
    transform: scale(1);
    opacity: 1;  
}

.video-container {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
.video-player {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 30px;
}
  
.play-button {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.recent-projects-section {
    width: 100%;
    color: #fff; 
    font-size: 2rem;
    letter-spacing: -3px;
    padding-top: 5rem;
}

.recent-projects-video {
    padding: 2rem 0;
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.recent-projects-video:hover {
    transform: scale(.9);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 999;
}

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    max-height: 100vh; /* Ensure it doesn't overflow the viewport */
    overflow: hidden;
  }

.video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000;
    padding: 20px;
    z-index: 1000;
    width: 80%;
}

a {
    color: #fff;
    text-decoration: none;
}

.close {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.recent-projects-section {
    position: relative;
    overflow: hidden;
}

.hover-link {
    display: inline-block;
    position: relative;
    overflow: hidden;  
    text-decoration: none; 
    vertical-align: top; 
    font-size: 1rem;
    letter-spacing: 0px;
}

.original-text, .hover-text {
    display: block;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(0);
    width: 100%;
}

.hover-text {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(100%);
    opacity: 0;
}

.hover-link:hover .original-text {
    transform: translateY(-100%);
    opacity: 0;
}

.hover-link:hover .hover-text {
    transform: translateY(0);
    opacity: 1;
}



.recent-projects-video {
    width: 100%;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .recent-projects-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .recent-projects-text {
        width: 70%; 
    }

    .recent-projects-text h2 {
        font-size: larger;
    }

    .recent-projects-video{
        width: 30%;
    }
    
    .recent-projects-text {
        padding: 20px;
        text-align: center;
    }
    .image-slider-container .some-of-our-work-heading {
        padding-top: 20rem;
    }
}










  
  